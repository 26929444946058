<template>
  <div>
    <b-overlay :show="show">
      <b-row>
        <b-col
          style="display: flex; justify-content: end"
          cols="12"
        >
          <b-button
            variant="relief-info"
            @click="guardarMetricas"
          >
            Guardar Metricas
          </b-button>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            style-class="vgt-table condensed"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'meses'">
                <table>
                  <tr>
                    <th
                      v-for="(item, index) in props.row.meses"
                      :key="index"
                    >
                      <b-form-checkbox
                        v-model="item.checked"
                        @input="updateCheckbox(props.row, index)"
                      />
                      <br> <label>{{ item.short }}</label>
                    </th>
                  </tr>
                </table>
              </span>
              <span v-else-if="props.column.field === 'formulario'">
                {{ props.index + 1 }}. {{ props.row.formulario }}
              </span>
              <span v-else-if="props.column.field === 'metrica'">
                <b-form-group
                  label="Ingrese Metrica Mensual"
                  label-for="metrica"
                >
                  <b-form-input
                    id="metrica"
                    v-model="props.row.metrica"
                    type="number"
                    @change="cambiarValorInput(props.row)"
                    @keydown="blockInvalidKeys"
                  />
                </b-form-group>
              </span>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton, BFormCheckbox, BFormInput, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { findMetricas, updateCreateMetricas } from '@/utils/administrador/metricas'
import { mensajeInformativo } from '@/utils/mensajes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    VueGoodTable,
    BOverlay,
  },
  data() {
    return {
      show: false,
      metrica: '',
      columns: [
        {
          label: 'Nombre',
          field: 'formulario',
        },
        {
          label: 'Meses',
          field: 'meses',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Metrica Mensual',
          field: 'metrica',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      rows: [],
      meses: [
        {
          short: 'Ene', nombre: 'Enero', numero: 1, checked: false,
        },
        {
          short: 'Feb', nombre: 'Febrero', numero: 2, checked: false,
        },
        {
          short: 'Mar', nombre: 'Marzo', numero: 3, checked: false,
        },
        {
          short: 'Abr', nombre: 'Abril', numero: 4, checked: false,
        },
        {
          short: 'May', nombre: 'Mayo', numero: 5, checked: false,
        },
        {
          short: 'Jun', nombre: 'Junio', numero: 6, checked: false,
        },
        {
          short: 'Jul', nombre: 'Julio', numero: 7, checked: false,
        },
        {
          short: 'Ago', nombre: 'Agosto', numero: 8, checked: false,
        },
        {
          short: 'Sep', nombre: 'Septiembre', numero: 9, checked: false,
        },
        {
          short: 'Oct', nombre: 'Octubre', numero: 10, checked: false,
        },
        {
          short: 'Nov', nombre: 'Noviembre', numero: 11, checked: false,
        },
        {
          short: 'Dic', nombre: 'Diciembre', numero: 12, checked: false,
        },
      ],
    }
  },
  async created() {
    this.rows = []
    await this.loadReport()
    this.show = false
  },
  methods: {
    blockInvalidKeys(event) {
      const allowedCharacters = /[0-9]/ // Expresión regular para permitir solo números
      const { keyCode } = event
      if (keyCode !== 8 && keyCode !== 46 && !allowedCharacters.test(event.key)) {
        event.preventDefault()
      }
    },
    cambiarValorInput(row) {
      this.rows[row.originalIndex].metrica = Number(row.metrica)
    },
    updateCheckbox(row, index) {
      this.rows[row.originalIndex].meses[index].checked = row.meses[index].checked
    },
    async guardarMetricas() {
      this.show = true

      // eslint-disable-next-line no-restricted-syntax
      for await (const row of this.rows) {
        await updateCreateMetricas(row, 1)
      }

      mensajeInformativo('Guardada', 'Administración de Métricas Actualizada Perfectamente!')
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Administración de Métricas Actualizada!',
          icon: 'CheckIcon',
          variant: 'success',
          text: 'Administración de Métricas Actualizada Correctamente!',
        },
      })
      await this.loadReport()
      this.show = false
    },
    async loadReport() {
      this.show = true
      const filter = {}
      const data = await findMetricas(filter)
      this.rows = []

      if (data) {
        data.forEach(item => {
          if (!item.meses) {
            // eslint-disable-next-line no-param-reassign
            item.meses = []
            this.meses.forEach(mes => {
              item.meses.push({ ...mes })
            })
          }
        })
        this.rows = data
      }
      this.show = false
    },
  },
}
</script>

<style scoped>

</style>
