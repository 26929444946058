import { render, staticRenderFns } from "./metricas-index.vue?vue&type=template&id=0bd5679e&scoped=true"
import script from "./metricas-index.vue?vue&type=script&lang=js"
export * from "./metricas-index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd5679e",
  null
  
)

export default component.exports